import React, { useEffect } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './media.reducer';

export const MediaDetail = (props: RouteComponentProps<{ id: string }>) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getEntity(props.match.params.id));
  }, []);

  const mediaEntity = useAppSelector(state => state.media.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="mediaDetailsHeading">
          <Translate contentKey="xtdtApp.media.detail.title">Media</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{mediaEntity.id}</dd>
          <dt>
            <span id="tieuDe">
              <Translate contentKey="xtdtApp.media.tieuDe">Tieu De</Translate>
            </span>
          </dt>
          <dd>{mediaEntity.tieuDe}</dd>
          <dt>
            <span id="loaiMedia">
              <Translate contentKey="xtdtApp.media.loaiMedia">Loai Media</Translate>
            </span>
          </dt>
          <dd>{mediaEntity.loaiMedia}</dd>
          <dt>
            <span id="link">
              <Translate contentKey="xtdtApp.media.link">Link</Translate>
            </span>
          </dt>
          <dd>{mediaEntity.link}</dd>
          <dt>
            <span id="hienThiTrangChu">
              <Translate contentKey="xtdtApp.media.hienThiTrangChu">Hien Thi Trang Chu</Translate>
            </span>
          </dt>
          <dd>{mediaEntity.hienThiTrangChu ? 'true' : 'false'}</dd>
          <dt>
            <span id="thuTu">
              <Translate contentKey="xtdtApp.media.thuTu">Thu Tu</Translate>
            </span>
          </dt>
          <dd>{mediaEntity.thuTu}</dd>
          <dt>
            <Translate contentKey="xtdtApp.media.fileEntry">File Entry</Translate>
          </dt>
          <dd>{mediaEntity.fileEntry ? mediaEntity.fileEntry.id : ''}</dd>
          <dt>
            <Translate contentKey="xtdtApp.media.gallery">Gallery</Translate>
          </dt>
          <dd>{mediaEntity.gallery ? mediaEntity.gallery.id : ''}</dd>
          <dt>
            <Translate contentKey="xtdtApp.media.baiViet">Bai Viet</Translate>
          </dt>
          <dd>
            {mediaEntity.baiViets
              ? mediaEntity.baiViets.map((val, i) => (
                  <span key={val.id}>
                    <a>{val.id}</a>
                    {mediaEntity.baiViets && i === mediaEntity.baiViets.length - 1 ? '' : ', '}
                  </span>
                ))
              : null}
          </dd>
          <dt>
            <Translate contentKey="xtdtApp.media.duAn">Du An</Translate>
          </dt>
          <dd>
            {mediaEntity.duAns
              ? mediaEntity.duAns.map((val, i) => (
                  <span key={val.id}>
                    <a>{val.id}</a>
                    {mediaEntity.duAns && i === mediaEntity.duAns.length - 1 ? '' : ', '}
                  </span>
                ))
              : null}
          </dd>
        </dl>
        <Button tag={Link} to="/media" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/media/${mediaEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default MediaDetail;
