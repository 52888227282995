import React, { useState, useEffect } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col, FormText } from 'reactstrap';
import { isNumber, Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { IDonViHanhChinh } from 'app/shared/model/don-vi-hanh-chinh.model';
import { getEntities as getDonViHanhChinhs } from 'app/entities/don-vi-hanh-chinh/don-vi-hanh-chinh.reducer';
import { IDoanhNghiep } from 'app/shared/model/doanh-nghiep.model';
import { getEntities as getDoanhNghieps } from 'app/entities/doanh-nghiep/doanh-nghiep.reducer';
import { IKhuCongNghiep } from 'app/shared/model/khu-cong-nghiep.model';
import { TrangThaiHoatDongEnum } from 'app/shared/model/enumerations/trang-thai-hoat-dong-enum.model';
import { getEntity, updateEntity, createEntity, reset } from './khu-cong-nghiep.reducer';

export const KhuCongNghiepUpdate = (props: RouteComponentProps<{ id: string }>) => {
  const dispatch = useAppDispatch();

  const [isNew] = useState(!props.match.params || !props.match.params.id);

  const donViHanhChinhs = useAppSelector(state => state.donViHanhChinh.entities);
  const doanhNghieps = useAppSelector(state => state.doanhNghiep.entities);
  const khuCongNghiepEntity = useAppSelector(state => state.khuCongNghiep.entity);
  const loading = useAppSelector(state => state.khuCongNghiep.loading);
  const updating = useAppSelector(state => state.khuCongNghiep.updating);
  const updateSuccess = useAppSelector(state => state.khuCongNghiep.updateSuccess);
  const trangThaiHoatDongEnumValues = Object.keys(TrangThaiHoatDongEnum);
  const handleClose = () => {
    props.history.push('/khu-cong-nghiep');
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(props.match.params.id));
    }

    dispatch(getDonViHanhChinhs({}));
    dispatch(getDoanhNghieps({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    const entity = {
      ...khuCongNghiepEntity,
      ...values,
      doanhNghieps: mapIdList(values.doanhNghieps),
      diaPhuong: donViHanhChinhs.find(it => it.id.toString() === values.diaPhuong.toString()),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {}
      : {
          trangThaiHoatDong: 'DANG_HOAT_DONG',
          ...khuCongNghiepEntity,
          diaPhuong: khuCongNghiepEntity?.diaPhuong?.id,
          doanhNghieps: khuCongNghiepEntity?.doanhNghieps?.map(e => e.id.toString()),
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="xtdtApp.khuCongNghiep.home.createOrEditLabel" data-cy="KhuCongNghiepCreateUpdateHeading">
            <Translate contentKey="xtdtApp.khuCongNghiep.home.createOrEditLabel">Create or edit a KhuCongNghiep</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="khu-cong-nghiep-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                label={translate('xtdtApp.khuCongNghiep.ten')}
                id="khu-cong-nghiep-ten"
                name="ten"
                data-cy="ten"
                type="text"
              />
              <ValidatedField
                label={translate('xtdtApp.khuCongNghiep.hinhAnhKhuCongNghiep')}
                id="khu-cong-nghiep-hinhAnhKhuCongNghiep"
                name="hinhAnhKhuCongNghiep"
                data-cy="hinhAnhKhuCongNghiep"
                type="text"
              />
              <ValidatedField
                label={translate('xtdtApp.khuCongNghiep.diaDiem')}
                id="khu-cong-nghiep-diaDiem"
                name="diaDiem"
                data-cy="diaDiem"
                type="text"
              />
              <ValidatedField
                label={translate('xtdtApp.khuCongNghiep.chuDauTu')}
                id="khu-cong-nghiep-chuDauTu"
                name="chuDauTu"
                data-cy="chuDauTu"
                type="text"
              />
              <ValidatedField
                label={translate('xtdtApp.khuCongNghiep.hinhAnhViTriDiaLy')}
                id="khu-cong-nghiep-hinhAnhViTriDiaLy"
                name="hinhAnhViTriDiaLy"
                data-cy="hinhAnhViTriDiaLy"
                type="text"
              />
              <ValidatedField
                label={translate('xtdtApp.khuCongNghiep.moTaViTriDiaLy')}
                id="khu-cong-nghiep-moTaViTriDiaLy"
                name="moTaViTriDiaLy"
                data-cy="moTaViTriDiaLy"
                type="text"
              />
              <ValidatedField
                label={translate('xtdtApp.khuCongNghiep.hinhAnhDienTich')}
                id="khu-cong-nghiep-hinhAnhDienTich"
                name="hinhAnhDienTich"
                data-cy="hinhAnhDienTich"
                type="text"
              />
              <ValidatedField
                label={translate('xtdtApp.khuCongNghiep.moTaDienTich')}
                id="khu-cong-nghiep-moTaDienTich"
                name="moTaDienTich"
                data-cy="moTaDienTich"
                type="text"
              />
              <ValidatedField
                label={translate('xtdtApp.khuCongNghiep.tongVonDauTu')}
                id="khu-cong-nghiep-tongVonDauTu"
                name="tongVonDauTu"
                data-cy="tongVonDauTu"
                type="text"
              />
              <ValidatedField
                label={translate('xtdtApp.khuCongNghiep.trangThaiHoatDong')}
                id="khu-cong-nghiep-trangThaiHoatDong"
                name="trangThaiHoatDong"
                data-cy="trangThaiHoatDong"
                type="select"
              >
                {trangThaiHoatDongEnumValues.map(trangThaiHoatDongEnum => (
                  <option value={trangThaiHoatDongEnum} key={trangThaiHoatDongEnum}>
                    {translate('xtdtApp.TrangThaiHoatDongEnum.' + trangThaiHoatDongEnum)}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedField
                label={translate('xtdtApp.khuCongNghiep.mucTieu')}
                id="khu-cong-nghiep-mucTieu"
                name="mucTieu"
                data-cy="mucTieu"
                type="text"
              />
              <ValidatedField
                label={translate('xtdtApp.khuCongNghiep.danhMucNganhNgheUuTien')}
                id="khu-cong-nghiep-danhMucNganhNgheUuTien"
                name="danhMucNganhNgheUuTien"
                data-cy="danhMucNganhNgheUuTien"
                type="text"
              />
              <ValidatedField
                label={translate('xtdtApp.khuCongNghiep.nguonDien')}
                id="khu-cong-nghiep-nguonDien"
                name="nguonDien"
                data-cy="nguonDien"
                type="text"
              />
              <ValidatedField
                label={translate('xtdtApp.khuCongNghiep.nguonNuoc')}
                id="khu-cong-nghiep-nguonNuoc"
                name="nguonNuoc"
                data-cy="nguonNuoc"
                type="text"
              />
              <ValidatedField
                label={translate('xtdtApp.khuCongNghiep.heThongThoatNuocXuLyChatThai')}
                id="khu-cong-nghiep-heThongThoatNuocXuLyChatThai"
                name="heThongThoatNuocXuLyChatThai"
                data-cy="heThongThoatNuocXuLyChatThai"
                type="text"
              />
              <ValidatedField
                label={translate('xtdtApp.khuCongNghiep.vienThong')}
                id="khu-cong-nghiep-vienThong"
                name="vienThong"
                data-cy="vienThong"
                type="text"
              />
              <ValidatedField
                label={translate('xtdtApp.khuCongNghiep.haTangXaHoiVaDichVu')}
                id="khu-cong-nghiep-haTangXaHoiVaDichVu"
                name="haTangXaHoiVaDichVu"
                data-cy="haTangXaHoiVaDichVu"
                type="text"
              />
              <ValidatedField
                label={translate('xtdtApp.khuCongNghiep.giaChoThue')}
                id="khu-cong-nghiep-giaChoThue"
                name="giaChoThue"
                data-cy="giaChoThue"
                type="text"
              />
              <ValidatedField
                label={translate('xtdtApp.khuCongNghiep.uuDaiDauTu')}
                id="khu-cong-nghiep-uuDaiDauTu"
                name="uuDaiDauTu"
                data-cy="uuDaiDauTu"
                type="text"
              />
              <ValidatedField
                label={translate('xtdtApp.khuCongNghiep.geometry')}
                id="khu-cong-nghiep-geometry"
                name="geometry"
                data-cy="geometry"
                type="text"
              />
              <ValidatedField
                id="khu-cong-nghiep-diaPhuong"
                name="diaPhuong"
                data-cy="diaPhuong"
                label={translate('xtdtApp.khuCongNghiep.diaPhuong')}
                type="select"
              >
                <option value="" key="0" />
                {donViHanhChinhs
                  ? donViHanhChinhs.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                label={translate('xtdtApp.khuCongNghiep.doanhNghiep')}
                id="khu-cong-nghiep-doanhNghiep"
                data-cy="doanhNghiep"
                type="select"
                multiple
                name="doanhNghieps"
              >
                <option value="" key="0" />
                {doanhNghieps
                  ? doanhNghieps.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/khu-cong-nghiep" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default KhuCongNghiepUpdate;
