import { IKhuCongNghiep } from 'app/shared/model/khu-cong-nghiep.model';

export interface IDoanhNghiep {
  id?: number;
  ten?: string | null;
  ma?: string | null;
  khuCongNghieps?: IKhuCongNghiep[] | null;
}

export const defaultValue: Readonly<IDoanhNghiep> = {};
