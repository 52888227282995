import React from 'react';
import { Switch } from 'react-router-dom';
import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import DonViHanhChinh from './don-vi-hanh-chinh';
import FileEntry from './file-entry';
import Gallery from './gallery';
import Media from './media';
import LoaiQuyHoach from './loai-quy-hoach';
import BaiViet from './bai-viet';
import LinhVuc from './linh-vuc';
import DuAn from './du-an';
import KhuCongNghiep from './khu-cong-nghiep';
import DoanhNghiep from './doanh-nghiep';
import ThongTinDiaPhuong from './thong-tin-dia-phuong';
import LoaiDuAn from './loai-du-an';
import ThuVien from './thu-vien';
/* jhipster-needle-add-route-import - JHipster will add routes here */

export default ({ match }) => {
  return (
    <div>
      <Switch>
        {/* prettier-ignore */}
        <ErrorBoundaryRoute path={`${match.url}don-vi-hanh-chinh`} component={DonViHanhChinh} />
        <ErrorBoundaryRoute path={`${match.url}file-entry`} component={FileEntry} />
        <ErrorBoundaryRoute path={`${match.url}gallery`} component={Gallery} />
        <ErrorBoundaryRoute path={`${match.url}media`} component={Media} />
        <ErrorBoundaryRoute path={`${match.url}loai-quy-hoach`} component={LoaiQuyHoach} />
        <ErrorBoundaryRoute path={`${match.url}bai-viet`} component={BaiViet} />
        <ErrorBoundaryRoute path={`${match.url}linh-vuc`} component={LinhVuc} />
        <ErrorBoundaryRoute path={`${match.url}du-an`} component={DuAn} />
        <ErrorBoundaryRoute path={`${match.url}khu-cong-nghiep`} component={KhuCongNghiep} />
        <ErrorBoundaryRoute path={`${match.url}doanh-nghiep`} component={DoanhNghiep} />
        <ErrorBoundaryRoute path={`${match.url}thong-tin-dia-phuong`} component={ThongTinDiaPhuong} />
        <ErrorBoundaryRoute path={`${match.url}loai-du-an`} component={LoaiDuAn} />
        <ErrorBoundaryRoute path={`${match.url}thu-vien`} component={ThuVien} />
        {/* jhipster-needle-add-route-path - JHipster will add routes here */}
      </Switch>
    </div>
  );
};
