export interface IThongTinDiaPhuong {
  id?: number;
  moTa?: string | null;
  dienTich?: number | null;
  danSo?: number | null;
  soLaoDong?: number | null;
  gRDP?: number | null;
  gRDPNguoi?: number | null;
}

export const defaultValue: Readonly<IThongTinDiaPhuong> = {};
