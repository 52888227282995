import React, { useEffect } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './don-vi-hanh-chinh.reducer';

export const DonViHanhChinhDetail = (props: RouteComponentProps<{ id: string }>) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getEntity(props.match.params.id));
  }, []);

  const donViHanhChinhEntity = useAppSelector(state => state.donViHanhChinh.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="donViHanhChinhDetailsHeading">
          <Translate contentKey="xtdtApp.donViHanhChinh.detail.title">DonViHanhChinh</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{donViHanhChinhEntity.id}</dd>
          <dt>
            <span id="ten">
              <Translate contentKey="xtdtApp.donViHanhChinh.ten">Ten</Translate>
            </span>
          </dt>
          <dd>{donViHanhChinhEntity.ten}</dd>
          <dt>
            <span id="ma">
              <Translate contentKey="xtdtApp.donViHanhChinh.ma">Ma</Translate>
            </span>
          </dt>
          <dd>{donViHanhChinhEntity.ma}</dd>
          <dt>
            <span id="cap">
              <Translate contentKey="xtdtApp.donViHanhChinh.cap">Cap</Translate>
            </span>
          </dt>
          <dd>{donViHanhChinhEntity.cap}</dd>
          <dt>
            <span id="geometry">
              <Translate contentKey="xtdtApp.donViHanhChinh.geometry">Geometry</Translate>
            </span>
          </dt>
          <dd>{donViHanhChinhEntity.geometry}</dd>
          <dt>
            <Translate contentKey="xtdtApp.donViHanhChinh.thongTinChung">Thong Tin Chung</Translate>
          </dt>
          <dd>{donViHanhChinhEntity.thongTinChung ? donViHanhChinhEntity.thongTinChung.id : ''}</dd>
          <dt>
            <Translate contentKey="xtdtApp.donViHanhChinh.cha">Cha</Translate>
          </dt>
          <dd>{donViHanhChinhEntity.cha ? donViHanhChinhEntity.cha.id : ''}</dd>
        </dl>
        <Button tag={Link} to="/don-vi-hanh-chinh" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/don-vi-hanh-chinh/${donViHanhChinhEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default DonViHanhChinhDetail;
