import { IDonViHanhChinh } from 'app/shared/model/don-vi-hanh-chinh.model';
import { ILinhVuc } from 'app/shared/model/linh-vuc.model';
import { ILoaiDuAn } from 'app/shared/model/loai-du-an.model';
import { IMedia } from 'app/shared/model/media.model';

export interface IDuAn {
  id?: number;
  ten?: string | null;
  hinhAnhDuAn?: string | null;
  diaDiem?: string | null;
  dienTichCongSuat?: number | null;
  viTriDiaLy?: string | null;
  mucTieu?: string | null;
  hinhAnhMucTieu?: string | null;
  hienTrangSuDungDat?: string | null;
  danCu?: string | null;
  loiTheDauTu?: string | null;
  suPhuHopVoiHoSoQuyHoach?: string | null;
  hieuQua?: string | null;
  ghiChu?: string | null;
  hinhAnhSoDoViTri?: string | null;
  geometry?: string | null;
  diaPhuong?: IDonViHanhChinh | null;
  linhVuc?: ILinhVuc | null;
  loaiDuAn?: ILoaiDuAn | null;
  fileDinhKems?: IMedia[] | null;
}

export const defaultValue: Readonly<IDuAn> = {};
