import React, { useEffect } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './thu-vien.reducer';

export const ThuVienDetail = (props: RouteComponentProps<{ id: string }>) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getEntity(props.match.params.id));
  }, []);

  const thuVienEntity = useAppSelector(state => state.thuVien.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="thuVienDetailsHeading">
          <Translate contentKey="xtdtApp.thuVien.detail.title">ThuVien</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{thuVienEntity.id}</dd>
          <dt>
            <span id="tieuDe">
              <Translate contentKey="xtdtApp.thuVien.tieuDe">Tieu De</Translate>
            </span>
          </dt>
          <dd>{thuVienEntity.tieuDe}</dd>
          <dt>
            <span id="loaiThuVien">
              <Translate contentKey="xtdtApp.thuVien.loaiThuVien">Loai Thu Vien</Translate>
            </span>
          </dt>
          <dd>{thuVienEntity.loaiThuVien}</dd>
          <dt>
            <span id="link">
              <Translate contentKey="xtdtApp.thuVien.link">Link</Translate>
            </span>
          </dt>
          <dd>{thuVienEntity.link}</dd>
          <dt>
            <span id="hienThiTrangChu">
              <Translate contentKey="xtdtApp.thuVien.hienThiTrangChu">Hien Thi Trang Chu</Translate>
            </span>
          </dt>
          <dd>{thuVienEntity.hienThiTrangChu ? 'true' : 'false'}</dd>
          <dt>
            <span id="thuTuUuTien">
              <Translate contentKey="xtdtApp.thuVien.thuTuUuTien">Thu Tu Uu Tien</Translate>
            </span>
          </dt>
          <dd>{thuVienEntity.thuTuUuTien}</dd>
        </dl>
        <Button tag={Link} to="/thu-vien" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/thu-vien/${thuVienEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default ThuVienDetail;
