import { IThongTinDiaPhuong } from 'app/shared/model/thong-tin-dia-phuong.model';

export interface IDonViHanhChinh {
  id?: number;
  ten?: string | null;
  ma?: string | null;
  cap?: number | null;
  geometry?: string | null;
  thongTinChung?: IThongTinDiaPhuong | null;
  cha?: IDonViHanhChinh | null;
}

export const defaultValue: Readonly<IDonViHanhChinh> = {};
