import { LoaiThuVienEnum } from 'app/shared/model/enumerations/loai-thu-vien-enum.model';

export interface IThuVien {
  id?: number;
  tieuDe?: string | null;
  loaiThuVien?: LoaiThuVienEnum | null;
  link?: string | null;
  hienThiTrangChu?: boolean | null;
  thuTuUuTien?: number | null;
}

export const defaultValue: Readonly<IThuVien> = {
  hienThiTrangChu: false,
};
