import { IDonViHanhChinh } from 'app/shared/model/don-vi-hanh-chinh.model';
import { IDoanhNghiep } from 'app/shared/model/doanh-nghiep.model';
import { TrangThaiHoatDongEnum } from 'app/shared/model/enumerations/trang-thai-hoat-dong-enum.model';

export interface IKhuCongNghiep {
  id?: number;
  ten?: string | null;
  hinhAnhKhuCongNghiep?: string | null;
  diaDiem?: string | null;
  chuDauTu?: string | null;
  hinhAnhViTriDiaLy?: string | null;
  moTaViTriDiaLy?: string | null;
  hinhAnhDienTich?: string | null;
  moTaDienTich?: string | null;
  tongVonDauTu?: number | null;
  trangThaiHoatDong?: TrangThaiHoatDongEnum | null;
  mucTieu?: string | null;
  danhMucNganhNgheUuTien?: string | null;
  nguonDien?: string | null;
  nguonNuoc?: string | null;
  heThongThoatNuocXuLyChatThai?: string | null;
  vienThong?: string | null;
  haTangXaHoiVaDichVu?: string | null;
  giaChoThue?: string | null;
  uuDaiDauTu?: string | null;
  geometry?: string | null;
  diaPhuong?: IDonViHanhChinh | null;
  doanhNghieps?: IDoanhNghiep[] | null;
}

export const defaultValue: Readonly<IKhuCongNghiep> = {};
