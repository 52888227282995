import React, { useState, useEffect } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col, FormText } from 'reactstrap';
import { isNumber, Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { IDonViHanhChinh } from 'app/shared/model/don-vi-hanh-chinh.model';
import { getEntities as getDonViHanhChinhs } from 'app/entities/don-vi-hanh-chinh/don-vi-hanh-chinh.reducer';
import { ILinhVuc } from 'app/shared/model/linh-vuc.model';
import { getEntities as getLinhVucs } from 'app/entities/linh-vuc/linh-vuc.reducer';
import { ILoaiDuAn } from 'app/shared/model/loai-du-an.model';
import { getEntities as getLoaiDuAns } from 'app/entities/loai-du-an/loai-du-an.reducer';
import { IMedia } from 'app/shared/model/media.model';
import { getEntities as getMedia } from 'app/entities/media/media.reducer';
import { IDuAn } from 'app/shared/model/du-an.model';
import { getEntity, updateEntity, createEntity, reset } from './du-an.reducer';

export const DuAnUpdate = (props: RouteComponentProps<{ id: string }>) => {
  const dispatch = useAppDispatch();

  const [isNew] = useState(!props.match.params || !props.match.params.id);

  const donViHanhChinhs = useAppSelector(state => state.donViHanhChinh.entities);
  const linhVucs = useAppSelector(state => state.linhVuc.entities);
  const loaiDuAns = useAppSelector(state => state.loaiDuAn.entities);
  const media = useAppSelector(state => state.media.entities);
  const duAnEntity = useAppSelector(state => state.duAn.entity);
  const loading = useAppSelector(state => state.duAn.loading);
  const updating = useAppSelector(state => state.duAn.updating);
  const updateSuccess = useAppSelector(state => state.duAn.updateSuccess);
  const handleClose = () => {
    props.history.push('/du-an');
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(props.match.params.id));
    }

    dispatch(getDonViHanhChinhs({}));
    dispatch(getLinhVucs({}));
    dispatch(getLoaiDuAns({}));
    dispatch(getMedia({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    const entity = {
      ...duAnEntity,
      ...values,
      diaPhuong: donViHanhChinhs.find(it => it.id.toString() === values.diaPhuong.toString()),
      linhVuc: linhVucs.find(it => it.id.toString() === values.linhVuc.toString()),
      loaiDuAn: loaiDuAns.find(it => it.id.toString() === values.loaiDuAn.toString()),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {}
      : {
          ...duAnEntity,
          diaPhuong: duAnEntity?.diaPhuong?.id,
          linhVuc: duAnEntity?.linhVuc?.id,
          loaiDuAn: duAnEntity?.loaiDuAn?.id,
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="xtdtApp.duAn.home.createOrEditLabel" data-cy="DuAnCreateUpdateHeading">
            <Translate contentKey="xtdtApp.duAn.home.createOrEditLabel">Create or edit a DuAn</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="du-an-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField label={translate('xtdtApp.duAn.ten')} id="du-an-ten" name="ten" data-cy="ten" type="text" />
              <ValidatedField
                label={translate('xtdtApp.duAn.hinhAnhDuAn')}
                id="du-an-hinhAnhDuAn"
                name="hinhAnhDuAn"
                data-cy="hinhAnhDuAn"
                type="text"
              />
              <ValidatedField label={translate('xtdtApp.duAn.diaDiem')} id="du-an-diaDiem" name="diaDiem" data-cy="diaDiem" type="text" />
              <ValidatedField
                label={translate('xtdtApp.duAn.dienTichCongSuat')}
                id="du-an-dienTichCongSuat"
                name="dienTichCongSuat"
                data-cy="dienTichCongSuat"
                type="text"
              />
              <ValidatedField
                label={translate('xtdtApp.duAn.viTriDiaLy')}
                id="du-an-viTriDiaLy"
                name="viTriDiaLy"
                data-cy="viTriDiaLy"
                type="text"
              />
              <ValidatedField label={translate('xtdtApp.duAn.mucTieu')} id="du-an-mucTieu" name="mucTieu" data-cy="mucTieu" type="text" />
              <ValidatedField
                label={translate('xtdtApp.duAn.hinhAnhMucTieu')}
                id="du-an-hinhAnhMucTieu"
                name="hinhAnhMucTieu"
                data-cy="hinhAnhMucTieu"
                type="text"
              />
              <ValidatedField
                label={translate('xtdtApp.duAn.hienTrangSuDungDat')}
                id="du-an-hienTrangSuDungDat"
                name="hienTrangSuDungDat"
                data-cy="hienTrangSuDungDat"
                type="text"
              />
              <ValidatedField label={translate('xtdtApp.duAn.danCu')} id="du-an-danCu" name="danCu" data-cy="danCu" type="text" />
              <ValidatedField
                label={translate('xtdtApp.duAn.loiTheDauTu')}
                id="du-an-loiTheDauTu"
                name="loiTheDauTu"
                data-cy="loiTheDauTu"
                type="text"
              />
              <ValidatedField
                label={translate('xtdtApp.duAn.suPhuHopVoiHoSoQuyHoach')}
                id="du-an-suPhuHopVoiHoSoQuyHoach"
                name="suPhuHopVoiHoSoQuyHoach"
                data-cy="suPhuHopVoiHoSoQuyHoach"
                type="text"
              />
              <ValidatedField label={translate('xtdtApp.duAn.hieuQua')} id="du-an-hieuQua" name="hieuQua" data-cy="hieuQua" type="text" />
              <ValidatedField label={translate('xtdtApp.duAn.ghiChu')} id="du-an-ghiChu" name="ghiChu" data-cy="ghiChu" type="text" />
              <ValidatedField
                label={translate('xtdtApp.duAn.hinhAnhSoDoViTri')}
                id="du-an-hinhAnhSoDoViTri"
                name="hinhAnhSoDoViTri"
                data-cy="hinhAnhSoDoViTri"
                type="text"
              />
              <ValidatedField
                label={translate('xtdtApp.duAn.geometry')}
                id="du-an-geometry"
                name="geometry"
                data-cy="geometry"
                type="text"
              />
              <ValidatedField
                id="du-an-diaPhuong"
                name="diaPhuong"
                data-cy="diaPhuong"
                label={translate('xtdtApp.duAn.diaPhuong')}
                type="select"
              >
                <option value="" key="0" />
                {donViHanhChinhs
                  ? donViHanhChinhs.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField id="du-an-linhVuc" name="linhVuc" data-cy="linhVuc" label={translate('xtdtApp.duAn.linhVuc')} type="select">
                <option value="" key="0" />
                {linhVucs
                  ? linhVucs.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="du-an-loaiDuAn"
                name="loaiDuAn"
                data-cy="loaiDuAn"
                label={translate('xtdtApp.duAn.loaiDuAn')}
                type="select"
              >
                <option value="" key="0" />
                {loaiDuAns
                  ? loaiDuAns.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/du-an" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default DuAnUpdate;
