import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import DonViHanhChinh from './don-vi-hanh-chinh';
import DonViHanhChinhDetail from './don-vi-hanh-chinh-detail';
import DonViHanhChinhUpdate from './don-vi-hanh-chinh-update';
import DonViHanhChinhDeleteDialog from './don-vi-hanh-chinh-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={DonViHanhChinhUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={DonViHanhChinhUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={DonViHanhChinhDetail} />
      <ErrorBoundaryRoute path={match.url} component={DonViHanhChinh} />
    </Switch>
    <ErrorBoundaryRoute exact path={`${match.url}/:id/delete`} component={DonViHanhChinhDeleteDialog} />
  </>
);

export default Routes;
