import donViHanhChinh from 'app/entities/don-vi-hanh-chinh/don-vi-hanh-chinh.reducer';
import fileEntry from 'app/entities/file-entry/file-entry.reducer';
import gallery from 'app/entities/gallery/gallery.reducer';
import media from 'app/entities/media/media.reducer';
import loaiQuyHoach from 'app/entities/loai-quy-hoach/loai-quy-hoach.reducer';
import baiViet from 'app/entities/bai-viet/bai-viet.reducer';
import linhVuc from 'app/entities/linh-vuc/linh-vuc.reducer';
import duAn from 'app/entities/du-an/du-an.reducer';
import khuCongNghiep from 'app/entities/khu-cong-nghiep/khu-cong-nghiep.reducer';
import doanhNghiep from 'app/entities/doanh-nghiep/doanh-nghiep.reducer';
import thongTinDiaPhuong from 'app/entities/thong-tin-dia-phuong/thong-tin-dia-phuong.reducer';
import loaiDuAn from 'app/entities/loai-du-an/loai-du-an.reducer';
import thuVien from 'app/entities/thu-vien/thu-vien.reducer';
/* jhipster-needle-add-reducer-import - JHipster will add reducer here */

const entitiesReducers = {
  donViHanhChinh,
  fileEntry,
  gallery,
  media,
  loaiQuyHoach,
  baiViet,
  linhVuc,
  duAn,
  khuCongNghiep,
  doanhNghiep,
  thongTinDiaPhuong,
  loaiDuAn,
  thuVien,
  /* jhipster-needle-add-reducer-combine - JHipster will add reducer here */
};

export default entitiesReducers;
