import { IFileEntry } from 'app/shared/model/file-entry.model';
import { IGallery } from 'app/shared/model/gallery.model';
import { IBaiViet } from 'app/shared/model/bai-viet.model';
import { IDuAn } from 'app/shared/model/du-an.model';
import { LoaiMediaEnum } from 'app/shared/model/enumerations/loai-media-enum.model';

export interface IMedia {
  id?: number;
  tieuDe?: string | null;
  loaiMedia?: LoaiMediaEnum | null;
  link?: string | null;
  hienThiTrangChu?: boolean | null;
  thuTu?: number | null;
  fileEntry?: IFileEntry | null;
  gallery?: IGallery | null;
  baiViets?: IBaiViet[] | null;
  duAns?: IDuAn[] | null;
}

export const defaultValue: Readonly<IMedia> = {
  hienThiTrangChu: false,
};
