import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import ThongTinDiaPhuong from './thong-tin-dia-phuong';
import ThongTinDiaPhuongDetail from './thong-tin-dia-phuong-detail';
import ThongTinDiaPhuongUpdate from './thong-tin-dia-phuong-update';
import ThongTinDiaPhuongDeleteDialog from './thong-tin-dia-phuong-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={ThongTinDiaPhuongUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={ThongTinDiaPhuongUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={ThongTinDiaPhuongDetail} />
      <ErrorBoundaryRoute path={match.url} component={ThongTinDiaPhuong} />
    </Switch>
    <ErrorBoundaryRoute exact path={`${match.url}/:id/delete`} component={ThongTinDiaPhuongDeleteDialog} />
  </>
);

export default Routes;
