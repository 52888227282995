import React, { useState, useEffect } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Input, InputGroup, FormGroup, Form, Row, Col, Table } from 'reactstrap';
import { Translate, translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { IThongTinDiaPhuong } from 'app/shared/model/thong-tin-dia-phuong.model';
import { searchEntities, getEntities } from './thong-tin-dia-phuong.reducer';

export const ThongTinDiaPhuong = (props: RouteComponentProps<{ url: string }>) => {
  const dispatch = useAppDispatch();

  const [search, setSearch] = useState('');

  const thongTinDiaPhuongList = useAppSelector(state => state.thongTinDiaPhuong.entities);
  const loading = useAppSelector(state => state.thongTinDiaPhuong.loading);

  useEffect(() => {
    dispatch(getEntities({}));
  }, []);

  const startSearching = e => {
    if (search) {
      dispatch(searchEntities({ query: search }));
    }
    e.preventDefault();
  };

  const clear = () => {
    setSearch('');
    dispatch(getEntities({}));
  };

  const handleSearch = event => setSearch(event.target.value);

  const handleSyncList = () => {
    dispatch(getEntities({}));
  };

  const { match } = props;

  return (
    <div>
      <h2 id="thong-tin-dia-phuong-heading" data-cy="ThongTinDiaPhuongHeading">
        <Translate contentKey="xtdtApp.thongTinDiaPhuong.home.title">Thong Tin Dia Phuongs</Translate>
        <div className="d-flex justify-content-end">
          <Button className="me-2" color="info" onClick={handleSyncList} disabled={loading}>
            <FontAwesomeIcon icon="sync" spin={loading} />{' '}
            <Translate contentKey="xtdtApp.thongTinDiaPhuong.home.refreshListLabel">Refresh List</Translate>
          </Button>
          <Link
            to="/thong-tin-dia-phuong/new"
            className="btn btn-primary jh-create-entity"
            id="jh-create-entity"
            data-cy="entityCreateButton"
          >
            <FontAwesomeIcon icon="plus" />
            &nbsp;
            <Translate contentKey="xtdtApp.thongTinDiaPhuong.home.createLabel">Create new Thong Tin Dia Phuong</Translate>
          </Link>
        </div>
      </h2>
      <Row>
        <Col sm="12">
          <Form onSubmit={startSearching}>
            <FormGroup>
              <InputGroup>
                <Input
                  type="text"
                  name="search"
                  defaultValue={search}
                  onChange={handleSearch}
                  placeholder={translate('xtdtApp.thongTinDiaPhuong.home.search')}
                />
                <Button className="input-group-addon">
                  <FontAwesomeIcon icon="search" />
                </Button>
                <Button type="reset" className="input-group-addon" onClick={clear}>
                  <FontAwesomeIcon icon="trash" />
                </Button>
              </InputGroup>
            </FormGroup>
          </Form>
        </Col>
      </Row>
      <div className="table-responsive">
        {thongTinDiaPhuongList && thongTinDiaPhuongList.length > 0 ? (
          <Table responsive>
            <thead>
              <tr>
                <th>
                  <Translate contentKey="xtdtApp.thongTinDiaPhuong.id">ID</Translate>
                </th>
                <th>
                  <Translate contentKey="xtdtApp.thongTinDiaPhuong.moTa">Mo Ta</Translate>
                </th>
                <th>
                  <Translate contentKey="xtdtApp.thongTinDiaPhuong.dienTich">Dien Tich</Translate>
                </th>
                <th>
                  <Translate contentKey="xtdtApp.thongTinDiaPhuong.danSo">Dan So</Translate>
                </th>
                <th>
                  <Translate contentKey="xtdtApp.thongTinDiaPhuong.soLaoDong">So Lao Dong</Translate>
                </th>
                <th>
                  <Translate contentKey="xtdtApp.thongTinDiaPhuong.gRDP">G RDP</Translate>
                </th>
                <th>
                  <Translate contentKey="xtdtApp.thongTinDiaPhuong.gRDPNguoi">G RDP Nguoi</Translate>
                </th>
                <th />
              </tr>
            </thead>
            <tbody>
              {thongTinDiaPhuongList.map((thongTinDiaPhuong, i) => (
                <tr key={`entity-${i}`} data-cy="entityTable">
                  <td>
                    <Button tag={Link} to={`/thong-tin-dia-phuong/${thongTinDiaPhuong.id}`} color="link" size="sm">
                      {thongTinDiaPhuong.id}
                    </Button>
                  </td>
                  <td>{thongTinDiaPhuong.moTa}</td>
                  <td>{thongTinDiaPhuong.dienTich}</td>
                  <td>{thongTinDiaPhuong.danSo}</td>
                  <td>{thongTinDiaPhuong.soLaoDong}</td>
                  <td>{thongTinDiaPhuong.gRDP}</td>
                  <td>{thongTinDiaPhuong.gRDPNguoi}</td>
                  <td className="text-end">
                    <div className="btn-group flex-btn-group-container">
                      <Button
                        tag={Link}
                        to={`/thong-tin-dia-phuong/${thongTinDiaPhuong.id}`}
                        color="info"
                        size="sm"
                        data-cy="entityDetailsButton"
                      >
                        <FontAwesomeIcon icon="eye" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.view">View</Translate>
                        </span>
                      </Button>
                      <Button
                        tag={Link}
                        to={`/thong-tin-dia-phuong/${thongTinDiaPhuong.id}/edit`}
                        color="primary"
                        size="sm"
                        data-cy="entityEditButton"
                      >
                        <FontAwesomeIcon icon="pencil-alt" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.edit">Edit</Translate>
                        </span>
                      </Button>
                      <Button
                        tag={Link}
                        to={`/thong-tin-dia-phuong/${thongTinDiaPhuong.id}/delete`}
                        color="danger"
                        size="sm"
                        data-cy="entityDeleteButton"
                      >
                        <FontAwesomeIcon icon="trash" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.delete">Delete</Translate>
                        </span>
                      </Button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          !loading && (
            <div className="alert alert-warning">
              <Translate contentKey="xtdtApp.thongTinDiaPhuong.home.notFound">No Thong Tin Dia Phuongs found</Translate>
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default ThongTinDiaPhuong;
