import React, { useEffect } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './du-an.reducer';

export const DuAnDetail = (props: RouteComponentProps<{ id: string }>) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getEntity(props.match.params.id));
  }, []);

  const duAnEntity = useAppSelector(state => state.duAn.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="duAnDetailsHeading">
          <Translate contentKey="xtdtApp.duAn.detail.title">DuAn</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{duAnEntity.id}</dd>
          <dt>
            <span id="ten">
              <Translate contentKey="xtdtApp.duAn.ten">Ten</Translate>
            </span>
          </dt>
          <dd>{duAnEntity.ten}</dd>
          <dt>
            <span id="hinhAnhDuAn">
              <Translate contentKey="xtdtApp.duAn.hinhAnhDuAn">Hinh Anh Du An</Translate>
            </span>
          </dt>
          <dd>{duAnEntity.hinhAnhDuAn}</dd>
          <dt>
            <span id="diaDiem">
              <Translate contentKey="xtdtApp.duAn.diaDiem">Dia Diem</Translate>
            </span>
          </dt>
          <dd>{duAnEntity.diaDiem}</dd>
          <dt>
            <span id="dienTichCongSuat">
              <Translate contentKey="xtdtApp.duAn.dienTichCongSuat">Dien Tich Cong Suat</Translate>
            </span>
          </dt>
          <dd>{duAnEntity.dienTichCongSuat}</dd>
          <dt>
            <span id="viTriDiaLy">
              <Translate contentKey="xtdtApp.duAn.viTriDiaLy">Vi Tri Dia Ly</Translate>
            </span>
          </dt>
          <dd>{duAnEntity.viTriDiaLy}</dd>
          <dt>
            <span id="mucTieu">
              <Translate contentKey="xtdtApp.duAn.mucTieu">Muc Tieu</Translate>
            </span>
          </dt>
          <dd>{duAnEntity.mucTieu}</dd>
          <dt>
            <span id="hinhAnhMucTieu">
              <Translate contentKey="xtdtApp.duAn.hinhAnhMucTieu">Hinh Anh Muc Tieu</Translate>
            </span>
          </dt>
          <dd>{duAnEntity.hinhAnhMucTieu}</dd>
          <dt>
            <span id="hienTrangSuDungDat">
              <Translate contentKey="xtdtApp.duAn.hienTrangSuDungDat">Hien Trang Su Dung Dat</Translate>
            </span>
          </dt>
          <dd>{duAnEntity.hienTrangSuDungDat}</dd>
          <dt>
            <span id="danCu">
              <Translate contentKey="xtdtApp.duAn.danCu">Dan Cu</Translate>
            </span>
          </dt>
          <dd>{duAnEntity.danCu}</dd>
          <dt>
            <span id="loiTheDauTu">
              <Translate contentKey="xtdtApp.duAn.loiTheDauTu">Loi The Dau Tu</Translate>
            </span>
          </dt>
          <dd>{duAnEntity.loiTheDauTu}</dd>
          <dt>
            <span id="suPhuHopVoiHoSoQuyHoach">
              <Translate contentKey="xtdtApp.duAn.suPhuHopVoiHoSoQuyHoach">Su Phu Hop Voi Ho So Quy Hoach</Translate>
            </span>
          </dt>
          <dd>{duAnEntity.suPhuHopVoiHoSoQuyHoach}</dd>
          <dt>
            <span id="hieuQua">
              <Translate contentKey="xtdtApp.duAn.hieuQua">Hieu Qua</Translate>
            </span>
          </dt>
          <dd>{duAnEntity.hieuQua}</dd>
          <dt>
            <span id="ghiChu">
              <Translate contentKey="xtdtApp.duAn.ghiChu">Ghi Chu</Translate>
            </span>
          </dt>
          <dd>{duAnEntity.ghiChu}</dd>
          <dt>
            <span id="hinhAnhSoDoViTri">
              <Translate contentKey="xtdtApp.duAn.hinhAnhSoDoViTri">Hinh Anh So Do Vi Tri</Translate>
            </span>
          </dt>
          <dd>{duAnEntity.hinhAnhSoDoViTri}</dd>
          <dt>
            <span id="geometry">
              <Translate contentKey="xtdtApp.duAn.geometry">Geometry</Translate>
            </span>
          </dt>
          <dd>{duAnEntity.geometry}</dd>
          <dt>
            <Translate contentKey="xtdtApp.duAn.diaPhuong">Dia Phuong</Translate>
          </dt>
          <dd>{duAnEntity.diaPhuong ? duAnEntity.diaPhuong.id : ''}</dd>
          <dt>
            <Translate contentKey="xtdtApp.duAn.linhVuc">Linh Vuc</Translate>
          </dt>
          <dd>{duAnEntity.linhVuc ? duAnEntity.linhVuc.id : ''}</dd>
          <dt>
            <Translate contentKey="xtdtApp.duAn.loaiDuAn">Loai Du An</Translate>
          </dt>
          <dd>{duAnEntity.loaiDuAn ? duAnEntity.loaiDuAn.id : ''}</dd>
        </dl>
        <Button tag={Link} to="/du-an" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/du-an/${duAnEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default DuAnDetail;
