import React, { useState, useEffect } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col, FormText } from 'reactstrap';
import { isNumber, Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { IThuVien } from 'app/shared/model/thu-vien.model';
import { LoaiThuVienEnum } from 'app/shared/model/enumerations/loai-thu-vien-enum.model';
import { getEntity, updateEntity, createEntity, reset } from './thu-vien.reducer';

export const ThuVienUpdate = (props: RouteComponentProps<{ id: string }>) => {
  const dispatch = useAppDispatch();

  const [isNew] = useState(!props.match.params || !props.match.params.id);

  const thuVienEntity = useAppSelector(state => state.thuVien.entity);
  const loading = useAppSelector(state => state.thuVien.loading);
  const updating = useAppSelector(state => state.thuVien.updating);
  const updateSuccess = useAppSelector(state => state.thuVien.updateSuccess);
  const loaiThuVienEnumValues = Object.keys(LoaiThuVienEnum);
  const handleClose = () => {
    props.history.push('/thu-vien');
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(props.match.params.id));
    }
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    const entity = {
      ...thuVienEntity,
      ...values,
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {}
      : {
          loaiThuVien: 'HINH_ANH',
          ...thuVienEntity,
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="xtdtApp.thuVien.home.createOrEditLabel" data-cy="ThuVienCreateUpdateHeading">
            <Translate contentKey="xtdtApp.thuVien.home.createOrEditLabel">Create or edit a ThuVien</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="thu-vien-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField label={translate('xtdtApp.thuVien.tieuDe')} id="thu-vien-tieuDe" name="tieuDe" data-cy="tieuDe" type="text" />
              <ValidatedField
                label={translate('xtdtApp.thuVien.loaiThuVien')}
                id="thu-vien-loaiThuVien"
                name="loaiThuVien"
                data-cy="loaiThuVien"
                type="select"
              >
                {loaiThuVienEnumValues.map(loaiThuVienEnum => (
                  <option value={loaiThuVienEnum} key={loaiThuVienEnum}>
                    {translate('xtdtApp.LoaiThuVienEnum.' + loaiThuVienEnum)}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedField label={translate('xtdtApp.thuVien.link')} id="thu-vien-link" name="link" data-cy="link" type="text" />
              <ValidatedField
                label={translate('xtdtApp.thuVien.hienThiTrangChu')}
                id="thu-vien-hienThiTrangChu"
                name="hienThiTrangChu"
                data-cy="hienThiTrangChu"
                check
                type="checkbox"
              />
              <ValidatedField
                label={translate('xtdtApp.thuVien.thuTuUuTien')}
                id="thu-vien-thuTuUuTien"
                name="thuTuUuTien"
                data-cy="thuTuUuTien"
                type="text"
              />
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/thu-vien" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default ThuVienUpdate;
