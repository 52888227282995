import React from 'react';
import { Translate } from 'react-jhipster';

import MenuItem from 'app/shared/layout/menus/menu-item';

const EntitiesMenu = () => {
  return (
    <>
      {/* prettier-ignore */}
      <MenuItem icon="asterisk" to="/don-vi-hanh-chinh">
        <Translate contentKey="global.menu.entities.donViHanhChinh" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/file-entry">
        <Translate contentKey="global.menu.entities.fileEntry" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/gallery">
        <Translate contentKey="global.menu.entities.gallery" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/media">
        <Translate contentKey="global.menu.entities.media" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/loai-quy-hoach">
        <Translate contentKey="global.menu.entities.loaiQuyHoach" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/bai-viet">
        <Translate contentKey="global.menu.entities.baiViet" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/linh-vuc">
        <Translate contentKey="global.menu.entities.linhVuc" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/du-an">
        <Translate contentKey="global.menu.entities.duAn" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/khu-cong-nghiep">
        <Translate contentKey="global.menu.entities.khuCongNghiep" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/doanh-nghiep">
        <Translate contentKey="global.menu.entities.doanhNghiep" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/thong-tin-dia-phuong">
        <Translate contentKey="global.menu.entities.thongTinDiaPhuong" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/loai-du-an">
        <Translate contentKey="global.menu.entities.loaiDuAn" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/thu-vien">
        <Translate contentKey="global.menu.entities.thuVien" />
      </MenuItem>
      {/* jhipster-needle-add-entity-to-menu - JHipster will add entities to the menu here */}
    </>
  );
};

export default EntitiesMenu as React.ComponentType<any>;
