import React, { useState, useEffect } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col, FormText } from 'reactstrap';
import { isNumber, Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { IDonViHanhChinh } from 'app/shared/model/don-vi-hanh-chinh.model';
import { getEntities as getDonViHanhChinhs } from 'app/entities/don-vi-hanh-chinh/don-vi-hanh-chinh.reducer';
import { ILoaiQuyHoach } from 'app/shared/model/loai-quy-hoach.model';
import { getEntities as getLoaiQuyHoaches } from 'app/entities/loai-quy-hoach/loai-quy-hoach.reducer';
import { IMedia } from 'app/shared/model/media.model';
import { getEntities as getMedia } from 'app/entities/media/media.reducer';
import { IBaiViet } from 'app/shared/model/bai-viet.model';
import { getEntity, updateEntity, createEntity, reset } from './bai-viet.reducer';

export const BaiVietUpdate = (props: RouteComponentProps<{ id: string }>) => {
  const dispatch = useAppDispatch();

  const [isNew] = useState(!props.match.params || !props.match.params.id);

  const donViHanhChinhs = useAppSelector(state => state.donViHanhChinh.entities);
  const loaiQuyHoaches = useAppSelector(state => state.loaiQuyHoach.entities);
  const media = useAppSelector(state => state.media.entities);
  const baiVietEntity = useAppSelector(state => state.baiViet.entity);
  const loading = useAppSelector(state => state.baiViet.loading);
  const updating = useAppSelector(state => state.baiViet.updating);
  const updateSuccess = useAppSelector(state => state.baiViet.updateSuccess);
  const handleClose = () => {
    props.history.push('/bai-viet');
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(props.match.params.id));
    }

    dispatch(getDonViHanhChinhs({}));
    dispatch(getLoaiQuyHoaches({}));
    dispatch(getMedia({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    const entity = {
      ...baiVietEntity,
      ...values,
      diaPhuong: donViHanhChinhs.find(it => it.id.toString() === values.diaPhuong.toString()),
      loaiQuyHoach: loaiQuyHoaches.find(it => it.id.toString() === values.loaiQuyHoach.toString()),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {}
      : {
          ...baiVietEntity,
          diaPhuong: baiVietEntity?.diaPhuong?.id,
          loaiQuyHoach: baiVietEntity?.loaiQuyHoach?.id,
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="xtdtApp.baiViet.home.createOrEditLabel" data-cy="BaiVietCreateUpdateHeading">
            <Translate contentKey="xtdtApp.baiViet.home.createOrEditLabel">Create or edit a BaiViet</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="bai-viet-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField label={translate('xtdtApp.baiViet.tieuDe')} id="bai-viet-tieuDe" name="tieuDe" data-cy="tieuDe" type="text" />
              <ValidatedField label={translate('xtdtApp.baiViet.moTa')} id="bai-viet-moTa" name="moTa" data-cy="moTa" type="text" />
              <ValidatedField
                label={translate('xtdtApp.baiViet.anhDaiDien')}
                id="bai-viet-anhDaiDien"
                name="anhDaiDien"
                data-cy="anhDaiDien"
                type="text"
              />
              <ValidatedField
                label={translate('xtdtApp.baiViet.noiDung')}
                id="bai-viet-noiDung"
                name="noiDung"
                data-cy="noiDung"
                type="textarea"
              />
              <ValidatedField
                id="bai-viet-diaPhuong"
                name="diaPhuong"
                data-cy="diaPhuong"
                label={translate('xtdtApp.baiViet.diaPhuong')}
                type="select"
              >
                <option value="" key="0" />
                {donViHanhChinhs
                  ? donViHanhChinhs.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="bai-viet-loaiQuyHoach"
                name="loaiQuyHoach"
                data-cy="loaiQuyHoach"
                label={translate('xtdtApp.baiViet.loaiQuyHoach')}
                type="select"
              >
                <option value="" key="0" />
                {loaiQuyHoaches
                  ? loaiQuyHoaches.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/bai-viet" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default BaiVietUpdate;
