import React, { useState, useEffect } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col, FormText } from 'reactstrap';
import { isNumber, Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { IFileEntry } from 'app/shared/model/file-entry.model';
import { getEntities as getFileEntries } from 'app/entities/file-entry/file-entry.reducer';
import { IGallery } from 'app/shared/model/gallery.model';
import { getEntities as getGalleries } from 'app/entities/gallery/gallery.reducer';
import { IBaiViet } from 'app/shared/model/bai-viet.model';
import { getEntities as getBaiViets } from 'app/entities/bai-viet/bai-viet.reducer';
import { IDuAn } from 'app/shared/model/du-an.model';
import { getEntities as getDuAns } from 'app/entities/du-an/du-an.reducer';
import { IMedia } from 'app/shared/model/media.model';
import { LoaiMediaEnum } from 'app/shared/model/enumerations/loai-media-enum.model';
import { getEntity, updateEntity, createEntity, reset } from './media.reducer';

export const MediaUpdate = (props: RouteComponentProps<{ id: string }>) => {
  const dispatch = useAppDispatch();

  const [isNew] = useState(!props.match.params || !props.match.params.id);

  const fileEntries = useAppSelector(state => state.fileEntry.entities);
  const galleries = useAppSelector(state => state.gallery.entities);
  const baiViets = useAppSelector(state => state.baiViet.entities);
  const duAns = useAppSelector(state => state.duAn.entities);
  const mediaEntity = useAppSelector(state => state.media.entity);
  const loading = useAppSelector(state => state.media.loading);
  const updating = useAppSelector(state => state.media.updating);
  const updateSuccess = useAppSelector(state => state.media.updateSuccess);
  const loaiMediaEnumValues = Object.keys(LoaiMediaEnum);
  const handleClose = () => {
    props.history.push('/media');
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(props.match.params.id));
    }

    dispatch(getFileEntries({}));
    dispatch(getGalleries({}));
    dispatch(getBaiViets({}));
    dispatch(getDuAns({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    const entity = {
      ...mediaEntity,
      ...values,
      baiViets: mapIdList(values.baiViets),
      duAns: mapIdList(values.duAns),
      fileEntry: fileEntries.find(it => it.id.toString() === values.fileEntry.toString()),
      gallery: galleries.find(it => it.id.toString() === values.gallery.toString()),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {}
      : {
          loaiMedia: 'IMAGE',
          ...mediaEntity,
          fileEntry: mediaEntity?.fileEntry?.id,
          gallery: mediaEntity?.gallery?.id,
          baiViets: mediaEntity?.baiViets?.map(e => e.id.toString()),
          duAns: mediaEntity?.duAns?.map(e => e.id.toString()),
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="xtdtApp.media.home.createOrEditLabel" data-cy="MediaCreateUpdateHeading">
            <Translate contentKey="xtdtApp.media.home.createOrEditLabel">Create or edit a Media</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="media-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField label={translate('xtdtApp.media.tieuDe')} id="media-tieuDe" name="tieuDe" data-cy="tieuDe" type="text" />
              <ValidatedField
                label={translate('xtdtApp.media.loaiMedia')}
                id="media-loaiMedia"
                name="loaiMedia"
                data-cy="loaiMedia"
                type="select"
              >
                {loaiMediaEnumValues.map(loaiMediaEnum => (
                  <option value={loaiMediaEnum} key={loaiMediaEnum}>
                    {translate('xtdtApp.LoaiMediaEnum.' + loaiMediaEnum)}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedField label={translate('xtdtApp.media.link')} id="media-link" name="link" data-cy="link" type="text" />
              <ValidatedField
                label={translate('xtdtApp.media.hienThiTrangChu')}
                id="media-hienThiTrangChu"
                name="hienThiTrangChu"
                data-cy="hienThiTrangChu"
                check
                type="checkbox"
              />
              <ValidatedField label={translate('xtdtApp.media.thuTu')} id="media-thuTu" name="thuTu" data-cy="thuTu" type="text" />
              <ValidatedField
                id="media-fileEntry"
                name="fileEntry"
                data-cy="fileEntry"
                label={translate('xtdtApp.media.fileEntry')}
                type="select"
              >
                <option value="" key="0" />
                {fileEntries
                  ? fileEntries.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField id="media-gallery" name="gallery" data-cy="gallery" label={translate('xtdtApp.media.gallery')} type="select">
                <option value="" key="0" />
                {galleries
                  ? galleries.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                label={translate('xtdtApp.media.baiViet')}
                id="media-baiViet"
                data-cy="baiViet"
                type="select"
                multiple
                name="baiViets"
              >
                <option value="" key="0" />
                {baiViets
                  ? baiViets.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField label={translate('xtdtApp.media.duAn')} id="media-duAn" data-cy="duAn" type="select" multiple name="duAns">
                <option value="" key="0" />
                {duAns
                  ? duAns.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/media" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default MediaUpdate;
