import React, { useEffect } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './khu-cong-nghiep.reducer';

export const KhuCongNghiepDetail = (props: RouteComponentProps<{ id: string }>) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getEntity(props.match.params.id));
  }, []);

  const khuCongNghiepEntity = useAppSelector(state => state.khuCongNghiep.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="khuCongNghiepDetailsHeading">
          <Translate contentKey="xtdtApp.khuCongNghiep.detail.title">KhuCongNghiep</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{khuCongNghiepEntity.id}</dd>
          <dt>
            <span id="ten">
              <Translate contentKey="xtdtApp.khuCongNghiep.ten">Ten</Translate>
            </span>
          </dt>
          <dd>{khuCongNghiepEntity.ten}</dd>
          <dt>
            <span id="hinhAnhKhuCongNghiep">
              <Translate contentKey="xtdtApp.khuCongNghiep.hinhAnhKhuCongNghiep">Hinh Anh Khu Cong Nghiep</Translate>
            </span>
          </dt>
          <dd>{khuCongNghiepEntity.hinhAnhKhuCongNghiep}</dd>
          <dt>
            <span id="diaDiem">
              <Translate contentKey="xtdtApp.khuCongNghiep.diaDiem">Dia Diem</Translate>
            </span>
          </dt>
          <dd>{khuCongNghiepEntity.diaDiem}</dd>
          <dt>
            <span id="chuDauTu">
              <Translate contentKey="xtdtApp.khuCongNghiep.chuDauTu">Chu Dau Tu</Translate>
            </span>
          </dt>
          <dd>{khuCongNghiepEntity.chuDauTu}</dd>
          <dt>
            <span id="hinhAnhViTriDiaLy">
              <Translate contentKey="xtdtApp.khuCongNghiep.hinhAnhViTriDiaLy">Hinh Anh Vi Tri Dia Ly</Translate>
            </span>
          </dt>
          <dd>{khuCongNghiepEntity.hinhAnhViTriDiaLy}</dd>
          <dt>
            <span id="moTaViTriDiaLy">
              <Translate contentKey="xtdtApp.khuCongNghiep.moTaViTriDiaLy">Mo Ta Vi Tri Dia Ly</Translate>
            </span>
          </dt>
          <dd>{khuCongNghiepEntity.moTaViTriDiaLy}</dd>
          <dt>
            <span id="hinhAnhDienTich">
              <Translate contentKey="xtdtApp.khuCongNghiep.hinhAnhDienTich">Hinh Anh Dien Tich</Translate>
            </span>
          </dt>
          <dd>{khuCongNghiepEntity.hinhAnhDienTich}</dd>
          <dt>
            <span id="moTaDienTich">
              <Translate contentKey="xtdtApp.khuCongNghiep.moTaDienTich">Mo Ta Dien Tich</Translate>
            </span>
          </dt>
          <dd>{khuCongNghiepEntity.moTaDienTich}</dd>
          <dt>
            <span id="tongVonDauTu">
              <Translate contentKey="xtdtApp.khuCongNghiep.tongVonDauTu">Tong Von Dau Tu</Translate>
            </span>
          </dt>
          <dd>{khuCongNghiepEntity.tongVonDauTu}</dd>
          <dt>
            <span id="trangThaiHoatDong">
              <Translate contentKey="xtdtApp.khuCongNghiep.trangThaiHoatDong">Trang Thai Hoat Dong</Translate>
            </span>
          </dt>
          <dd>{khuCongNghiepEntity.trangThaiHoatDong}</dd>
          <dt>
            <span id="mucTieu">
              <Translate contentKey="xtdtApp.khuCongNghiep.mucTieu">Muc Tieu</Translate>
            </span>
          </dt>
          <dd>{khuCongNghiepEntity.mucTieu}</dd>
          <dt>
            <span id="danhMucNganhNgheUuTien">
              <Translate contentKey="xtdtApp.khuCongNghiep.danhMucNganhNgheUuTien">Danh Muc Nganh Nghe Uu Tien</Translate>
            </span>
          </dt>
          <dd>{khuCongNghiepEntity.danhMucNganhNgheUuTien}</dd>
          <dt>
            <span id="nguonDien">
              <Translate contentKey="xtdtApp.khuCongNghiep.nguonDien">Nguon Dien</Translate>
            </span>
          </dt>
          <dd>{khuCongNghiepEntity.nguonDien}</dd>
          <dt>
            <span id="nguonNuoc">
              <Translate contentKey="xtdtApp.khuCongNghiep.nguonNuoc">Nguon Nuoc</Translate>
            </span>
          </dt>
          <dd>{khuCongNghiepEntity.nguonNuoc}</dd>
          <dt>
            <span id="heThongThoatNuocXuLyChatThai">
              <Translate contentKey="xtdtApp.khuCongNghiep.heThongThoatNuocXuLyChatThai">He Thong Thoat Nuoc Xu Ly Chat Thai</Translate>
            </span>
          </dt>
          <dd>{khuCongNghiepEntity.heThongThoatNuocXuLyChatThai}</dd>
          <dt>
            <span id="vienThong">
              <Translate contentKey="xtdtApp.khuCongNghiep.vienThong">Vien Thong</Translate>
            </span>
          </dt>
          <dd>{khuCongNghiepEntity.vienThong}</dd>
          <dt>
            <span id="haTangXaHoiVaDichVu">
              <Translate contentKey="xtdtApp.khuCongNghiep.haTangXaHoiVaDichVu">Ha Tang Xa Hoi Va Dich Vu</Translate>
            </span>
          </dt>
          <dd>{khuCongNghiepEntity.haTangXaHoiVaDichVu}</dd>
          <dt>
            <span id="giaChoThue">
              <Translate contentKey="xtdtApp.khuCongNghiep.giaChoThue">Gia Cho Thue</Translate>
            </span>
          </dt>
          <dd>{khuCongNghiepEntity.giaChoThue}</dd>
          <dt>
            <span id="uuDaiDauTu">
              <Translate contentKey="xtdtApp.khuCongNghiep.uuDaiDauTu">Uu Dai Dau Tu</Translate>
            </span>
          </dt>
          <dd>{khuCongNghiepEntity.uuDaiDauTu}</dd>
          <dt>
            <span id="geometry">
              <Translate contentKey="xtdtApp.khuCongNghiep.geometry">Geometry</Translate>
            </span>
          </dt>
          <dd>{khuCongNghiepEntity.geometry}</dd>
          <dt>
            <Translate contentKey="xtdtApp.khuCongNghiep.diaPhuong">Dia Phuong</Translate>
          </dt>
          <dd>{khuCongNghiepEntity.diaPhuong ? khuCongNghiepEntity.diaPhuong.id : ''}</dd>
          <dt>
            <Translate contentKey="xtdtApp.khuCongNghiep.doanhNghiep">Doanh Nghiep</Translate>
          </dt>
          <dd>
            {khuCongNghiepEntity.doanhNghieps
              ? khuCongNghiepEntity.doanhNghieps.map((val, i) => (
                  <span key={val.id}>
                    <a>{val.id}</a>
                    {khuCongNghiepEntity.doanhNghieps && i === khuCongNghiepEntity.doanhNghieps.length - 1 ? '' : ', '}
                  </span>
                ))
              : null}
          </dd>
        </dl>
        <Button tag={Link} to="/khu-cong-nghiep" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/khu-cong-nghiep/${khuCongNghiepEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default KhuCongNghiepDetail;
