import { IDonViHanhChinh } from 'app/shared/model/don-vi-hanh-chinh.model';
import { ILoaiQuyHoach } from 'app/shared/model/loai-quy-hoach.model';
import { IMedia } from 'app/shared/model/media.model';

export interface IBaiViet {
  id?: number;
  tieuDe?: string | null;
  moTa?: string | null;
  anhDaiDien?: string | null;
  noiDung?: string | null;
  diaPhuong?: IDonViHanhChinh | null;
  loaiQuyHoach?: ILoaiQuyHoach | null;
  fileDinhKems?: IMedia[] | null;
}

export const defaultValue: Readonly<IBaiViet> = {};
